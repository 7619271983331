import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import InputField from "../components/InputField";
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import $ from 'jquery';
import 'dropify/dist/js/dropify.min.js';
import 'dropify/dist/css/dropify.min.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './receipt.css'
import { useParams } from 'react-router-dom';
import { BiCartAdd, BiPlug, BiPlus, BiPrinter } from 'react-icons/bi';
import { useBarcode } from 'next-barcode';
import Preloader from '../components/Preloader';
import { FaLiraSign, FaThumbsUp } from 'react-icons/fa';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import styled from "styled-components";
import WebcamCapture from '../components/WebcamCapture';
import { Image } from 'primereact/image';
import * as faceapi from 'face-api.js';

export default function Verify() {
    const [formData, setFormData] = useState({
        qty: '',
        price: '',
        supplier_id: '',
        product_id: '',
        updateFormData:''
    });
    const { id } =  useParams(); // Extracts the ID from the URL

    const [saveFormData, setSaveFormData] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState([]);


    const [processing, setProcessing] = useState(false);
    const [openPrice, setOpenPrice] = useState(false);
    const [openQty, setOpenQty] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [users, setUsers] = useState([]); // Users for the dropdown
    const [suppliers, setcmSuppliers] = useState([]); // Suppliers for the dropdown
    const [suppliersData, setSuppliersData] = useState([]);
    const [saleRefs, setSaleRefs] = useState(null);
    const [sale, setSale] = useState([]);
    const [total, setTotal] = useState(0);
    const [productData, setProductData] = useState([]);
    const [FormArray, setFormArray] = useState([]);
    const [copyProduct, setCopyProduct] = useState([]);
    const [isVerify, setIsVerify] = useState(false);
    // Suppliers for the dropdown

    const faceRef = useRef();
    const passportRef = useRef();
    const isFirstRender = useRef(true);


    const [updateFormData, setUpdateFormData] = useState({
        qty: '',
        selling_price: '',
        product_id: '',
        updatedPrice:false,
        updatedQty:false,
    });



//     const handleRef =(value)=>{
//         setSaleRefs(value)

//  }

const videoRef = useRef(null);
const canvasRef = useRef(null);

const [mediaStream, setMediaStream] = useState(null);
const [capturedImage, setCapturedImage] = useState(null);

// // Function to convert base64 to Blob
// function base64ToBlob(base64, mimeType) {
//   const byteString = atob(base64.split(',')[1]);
//   const arrayBuffer = new ArrayBuffer(byteString.length);
//   const uint8Array = new Uint8Array(arrayBuffer);

//   for (let i = 0; i < byteString.length; i++) {
//     uint8Array[i] = byteString.charCodeAt(i);
//   }

//   return new Blob([uint8Array], { type: mimeType });
// }

// useEffect(() => {
//   startWebcam();
//   // Cleanup function to stop the webcam when the component unmounts
//   return () => stopWebcam();
// }, []);

// const startWebcam = async () => {
//   try {
//     const stream = await navigator.mediaDevices.getUserMedia({
//       video: {
//         facingMode: "user", // Request the front camera (selfie camera)
//       },
//     });
//     if (videoRef.current) {
//       videoRef.current.srcObject = stream;
//     }
//     setMediaStream(stream);
//   } catch (error) {
//     console.error("Error accessing webcam", error);
//   }
// };

// // Function to stop the webcam
// const stopWebcam = () => {
//   if (mediaStream) {
//     mediaStream.getTracks().forEach((track) => {
//       track.stop();
//     });
//     setMediaStream(null);
//   }
// };
// const captureImage = () => {
//     if (videoRef.current && canvasRef.current) {
//       const video = videoRef.current;
//       const canvas = canvasRef.current;
//       const context = canvas.getContext("2d");
  
//       // Set canvas dimensions to match video stream
//       if (context && video.videoWidth && video.videoHeight) {
//         canvas.width = video.videoWidth;
//         canvas.height = video.videoHeight;
  
//         // Draw video frame onto canvas
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
//         // Get image data URL from canvas
//         const imageDataUrl = canvas.toDataURL("image/jpeg");
  
//         // Convert base64 URL to Blob
//         const imageBlob = base64ToBlob(imageDataUrl.split(',')[1], 'image/jpeg');
  
//         // Set the captured image
//         setCapturedImage(imageBlob);
  
//         // Create a URL for the Blob and trigger a download
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(imageBlob) || faceRef.current.src; // Use the Blob or fallback to faceRef image source
//         link.download = 'captured-image.jpg';
//         document.body.appendChild(link);
//         link.click();  // Trigger the download
//         document.body.removeChild(link);  // Cleanup the link
  
//         // Stop the webcam
//         stopWebcam();
//       }
//     }
//   };

// // Function to reset state (clear media stream and refs)
// const resetState = () => {
//   stopWebcam(); // Stop the webcam if it's active
//   setCapturedImage(null); // Reset captured image
// };

// useEffect(() => {
//     const compareFaces = async () => {
//       try {
//         // Load face-api models
//         await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
//         await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
//         await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
//         await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
  
//         // Check if both images are available
//         if (!passportRef.current || !faceRef.current) {
//           console.error("One of the required image elements is missing.");
//           return;
//         }
  
//         // Detect face from passport image
//         const passportDetection = await faceapi
//           .detectSingleFace(passportRef.current, new faceapi.TinyFaceDetectorOptions())
//           .withFaceLandmarks()
//           .withFaceDescriptor();
  
//         // Detect face from selfie image
//         const selfieDetection = await faceapi
//           .detectSingleFace(faceRef.current, new faceapi.TinyFaceDetectorOptions())
//           .withFaceLandmarks()
//           .withFaceDescriptor();
  
//         // If a face is detected from both images, compare the descriptors
//         if (passportDetection && selfieDetection) {
//           const passportDescriptor = passportDetection.descriptor;
//           const selfieDescriptor = selfieDetection.descriptor;
  
//           // Calculate the Euclidean distance between the two face descriptors
//           const distance = faceapi.euclideanDistance(passportDescriptor, selfieDescriptor);
  
//           // Define a threshold for a match (commonly 0.6 for face recognition)
//           const threshold = 0.6;
//           const isMatch = distance < threshold;
  
//           if (isMatch) {
//             alert('Faces match!')
//             console.log("Faces match!");
//           } else {
//             console.log("Faces do not match.");
//           }
  
//           // Return the result (true if match, false if not)
//           return isMatch;
//         } else {
//           console.log("Face not detected in one or both images.");
//           return false;
//         }
//       } catch (error) {
//         console.error("Error during face comparison:", error);
//         return false;
//       }
//     };
  
//     compareFaces();
//   }, [passportRef, faceRef,isVerify]);

 const handleRef = (value) => {
    if (value && value.trim() !== "") {
        // Valid input, proceed with your logic
        setSaleRefs(value);
    } else {
        setSaleRefs(null);
        console.error("Invalid input: Reference ID cannot be empty");
        // Optionally, handle the invalid case (e.g., show an error message)
    }
};




    const toast = useRef(null);

    const accept = () => {
        handleCheckSale();
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected the request', life: 3000 });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Are you sure there is on this receipt receipt?. ',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept,
            reject
        });
    };

      const { inputRef } = useBarcode({
        value: saleRefs,
        options: {
          width:1,
          height:50,
          displayValue:false,
        }
      });




    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch suppliers data from your API
                const response = await axios.get('https://ktapi.scan-verify.com/api/suppliers');
                const suppliers = response.data;

                // Transform the suppliers data
                const transformedData = suppliers.map(supplier => ({
                    id: supplier.id,
                    name: supplier.business_name  // Assuming you want to use `business_name` as `name`
                }));

                // Update state with the transformed data
                setSuppliersData(transformedData);

                // Log the transformed data for verification
                console.log(transformedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    const  fetch  = async ()=>{
        try {
            const response = await axios.get('https://ktapi.scan-verify.com/api/products', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Product added:', response.data);


                console.log(response.data)

        } catch (error) {
            console.error('Error adding product:', error);
        }
     }

     const verifySale = async () => {
        setProcessing(true); // Start processing

        try {
            // Make the GET request to verify the sale
            const response = await axios.get(`https://ktapi.scan-verify.com/api/sales/verify/${saleRefs}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log("message", response.data.message)
            // Handle the successful response here
            if (response.data.success == true) {
                setIsVerify(true)
                showSuccess('success', 'Sale', response.data.message);

            } else {
                showSuccess('error', 'Sale', response?.data?.message || 'An error occurred while verifying the sale.');
            }

        } catch (error) {
            // Handle errors and display a relevant message
            console.error('Error verifying sale:', error);
            showSuccess('error', 'Sale', error.response?.data?.message || 'An error occurred while verifying the sale.');
        } finally {
            // Ensure processing is stopped regardless of the outcome
            setProcessing(false);
        }
    };



     const handleCheckSale = ()=>{
        verifySale();
     }

     function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // August
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        return `${month} ${day}, ${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
      }

    const  fetchSale  = async ()=>{
        try {
            const response = await axios.get(`https://ktapi.scan-verify.com/api/sales/${saleRefs}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setProcessing(false)
            setSale(response.data.sale.products)
            setProductData(response.data)
            setSaleRefs(response.data.sale.ref);



            // Calculate total outside of setState to avoid multiple re-renders
            let total = 0;
            response.data.sale.products.forEach((product) => {
            total += parseFloat(product.selling_price);
            });

            // Update the total state
            setTotal(total);

            console.log('Sale Data', response.data.sale);
            console.log('Shop Data', response.data.shop);



        } catch (error) {
            setProcessing(false)
            alert('Error verify receipt:', error);
        }
     }

const handleVerify = ()=>{
    setProcessing(true)
    fetchSale()
}


    // useEffect(() => {


    //     fetch();
    // }, []);




    const handleQtyChange = (value,change) => {

        setUpdateFormData((prevState) => ({
            ...prevState,
            qty: value,
            product_id:change,
            updatedQty:true,
        }));
    };

    const handlePrint = ()=>{
        window.print();
    }

    const handlePriceChange = (value,change) => {

        setUpdateFormData((prevState) => ({
            ...prevState,
            selling_price: value,
            product_id:change,
            updatedPrice:true,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };



    const handleSupplierChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            supplier_id: e.target.value
        }));
    };



    const handleProductChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            product_id: value,
        }));



        setSelectedProduct((prevState) => {
            const selectedProductExists = prevState.some((product) => product.id === value);

            if (!selectedProductExists) {
                setCopyProduct({ id: value, name: name });
                console.log("copy1", copyProduct)

                console.log("form1", selectedProduct)
                return [{ id: value, name: name }];
            } else {

                setCopyProduct(prevState.id);
                console.log("copy2", copyProduct)
                return prevState;
            }

        });



        console.log('selectedProduct:', selectedProduct)
    };



    useEffect(() => {
        console.log('updateFormData:', updateFormData)

    }, [updateFormData]);

    const showSuccess = (severity, summary, message) => {
        toast.current.show({ severity, summary, detail: message, life: 10000 });
    };

    useEffect(() => {
        setFormData((prevState) => ({
                 ...prevState,
                 price: copyProduct?.id?.selling_price,
                 qty: copyProduct?.id?.qty,
             }));

 }, [copyProduct]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);

        const form = new FormData();
        form.append('price', formData.price);
        form.append('qty', formData.qty);
        form.append('product_id', formData.product_id?.id);
        form.append('supplier_id', formData.supplier_id?.id);
        // form.append('updateFormData', JSON.stringify(FormArray));


        console.log("Copy prod", copyProduct)

        try {
            const response = await axios.post('https://ktapi.scan-verify.com/api/stocks', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Stock added:', response.data);
            showSuccess('success', 'Stock', 'Purchase successfully placed');
        } catch (error) {
            console.error('Error adding stock:', error);
            showSuccess('error', 'Stock', 'Failed to Purchase');
        } finally {
            setProcessing(false);
        }
    };


    const update = () => {
        const newFormArray = [...FormArray, updateFormData];
        setFormArray(newFormArray);
        setUpdateFormData([]);

        console.log('Form Data:', updateFormData);
        console.log('Current Data:', newFormArray);
        setIsUpdate(true);

        // Reset isUpdate after 200ms using a timeout
        setTimeout(() => {
            setIsUpdate(false);
        }, 200);
    };

    useEffect(() => {
        setOpenPrice(false);
        setOpenQty(false);

        console.log("Latest",FormArray)
        setFormData((prevState)=>({
            ...prevState,
            updateFormData:FormArray,
        }))

        if (!isUpdate) {
            setSelectedProduct([]);
        }
    }, [isUpdate]);


    const qtyBody = (rowData) => {

        return(
            <div className=''>
            <div className='flex items-center justify-center text-green-500'>
                <input
                    type="number"
                    onClick={(e)=>{setOpenQty(true);}}
                    name="qty"
                    placeholder="Quantity"
                    value={openQty? copyProduct.qty :  rowData.id.qty}
                    onChange={(e)=>{ handleQtyChange(e.target.value,rowData.id.id)} }
                    className="rounded-md border w-20 text-center border-green-500 bg-white px-1  text-base font-medium text-[#26ad38] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />


            </div>
            </div>
        )
      };

      const priceBody = (rowData) => {

        return(
            <div className='flex items-center justify-center text-green-500'><b>{"₦  "}</b>
             <input
                type="number"
                onClick={(e)=>{setOpenPrice(true);}}
                name="selling_price"
                placeholder="Price"
                value={openPrice? copyProduct.selling_price :  rowData.id.selling_price}
                onChange={(e)=>{ handlePriceChange(e.target.value,rowData.id.id) }}
                className="rounded-md border w-20 text-center border-green-500 bg-white px-1  text-base font-medium text-[#26ad38] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />


            </div>
        )
      };

      const status = (value)=>{
             switch (value) {
                case "expired":
                    return "EXPIRED"
                    break;

                case "notCollected":
                    return "NOT COLLECTED"
                    break;

                default:
                    return "NULL Receipt"
                    break;
             }
      }
      const actionButtonTemplate = (rowData) => {
        return (
          <div className='grid grid-cols-2 justify-center'>
            <div
            onClick={update}
             type='button'
           tooltip="Update Product"
              className="p-button-rounded px-4 my-auto border hover:bg-green-400  bg-green-500 items-center justify-center rounded-md text-white w-12 h-12 p-button-warning mr-2"
            >
              <i className="pi pi-plus-circle w-12 text-success"></i>Update
            </div>

          </div>
        );
      };

    return (
        <>
     <div className='container print:w-auto print:h-auto mt-4'>
     <div className='border border-spacing-1 border-green-200 bg-green-100 mt-4 text-green-900 rounded-md  px-2 py-3'>
            <div className='grid grid-cols-2 gapx-2 justify-center items-center'>
            <Toast ref={toast} />
            <ConfirmDialog />

                <InputField
                    id="ref"
                    label="Refferance ID"
                    type="text"
                    name="ref"
                    value={saleRefs}
                    placeholder="Refferance ID"
                    onChange={(e)=>{handleRef(e.target.value)}}
                />

            <div className='flex flex-row gap-x-4'>
            {/* <div className="container border-green-600 border-2">
                {capturedImage ? (
                    <>
                    <img src={capturedImage} ref={faceRef} alt="Captured" className="w-1/2 h-[30%] border-2 border-green-500" />
                    <button onClick={resetState}>Reset</button>
                    </>
                ) : (
                    <>
                    <video ref={videoRef} autoPlay muted />
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    <button onClick={captureImage} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Capture Image
                    </button>
                    </>
                )}
                </div>           */}
                <Image id='face' ref={passportRef} src={productData.sale?.customer?.passport} zoomSrc={productData.sale?.customer?.passport}  preview={true} loading='lazy' alt="Image" width="350" height="auto"  />
 
            </div>
            </div>
           
        </div>

       <div className='border border-spacing-1 gap-5 flex flex-row px-2 justify-center items-center'>
            <button
             onClick={()=>{handlePrint()}}
             className='bg-blue-500 md:py-5 sm:py-3 md:px-3  hover:bg-green-900 hover:ring-green-400 text-white mt-5 w-[20%] h-[40%] rounded-md flex flex-row justify-center items-center'>
                <BiPrinter/>
                <span>Print</span>
            </button>
            <button
            onClick={()=>{handleVerify()}}
            className='bg-green-500 md:py-5 sm:py-3 md:px-3 sm:px-1 hover:bg-green-900 hover:ring-green-400 ml-3  text-white mt-5 w-[20%] h-[40%]  rounded-md flex flex-row  justify-center items-center'>
                < FaThumbsUp/>
                <span>Verify</span>
            </button>
        </div>

       </div>

        <div className="flex items-center  print:w-auto print:h-auto justify-center p-4">
            <div id="receipt-print" className=" print:w-auto print:h-auto bg-white justify-center  p-4  rounded-md font-mono">
                <div  className=' pt-4 px-4 font-mono text-center'>
                    <img src='https://ktapi.scan-verify.com/logo/ktlogo.png' className='mx-auto w-30 h-30' />
                    <span className="mt-3 text-center font-bold">{productData?.shop?.shopname}</span>
                </div>
                <div  className='px-4 font-mono text-center'>
                <span className=" font-bold mt-0 font-serif" style={{ fontSize: '15px',fontFamily:'sans-serif', color:'black' }}>LOAN PURCHASE</span><br/>
                <div style={{ fontSize: '12px' }}>***********************</div>
                    <span className=" font-bold mt-0 font-serif" style={{ fontSize: '15px',fontFamily:'bolder', color:'green' }}>{status(productData?.sale?.picked_status)}</span><br/>
                <div style={{ fontSize: '12px' }}>************************</div>
                    <span className="mt-3"><b>Processed By:</b><br/>{productData?.sale?.agent?.name}</span><br/>
                </div>
                <span className=' text-1xl' style={{fontSize:'12px'}}><b>Customer:{productData.sale?.customer?.full_name}</b></span><br/>
                <span className=' text-1xl' style={{fontSize:'12px'}}><b>Date:</b>{formatDateTime(productData?.sale?.created_at)}</span><br/>
                <span className=' text-1xl' style={{fontSize:'12px'}}><b>Printed at: </b>{formatDateTime(new Date())}</span>
                <table className='w-full border-y border-collapse border-black'>
                    <thead>
                        <tr className='border-y border-black' style={{ fontSize: '12px' }}>
                            <th className='text-left px-1 py-1'>S/N</th>
                            <th className='text-left px-1 py-1'>Items</th>
                            <th className='text-left px-1 py-1'>Qtys</th>
                            <th className='text-left px-1 py-1'>Total</th>
                        </tr>
                    </thead>
                    <tbody>


                    {sale.map((product, index) => (
                        <tr key={index} style={{ fontSize: '12px' }}>
                            <td className='text-left px-1 py-1'>{index + 1}</td> 
                            <td className='text-left px-1 py-1'>{product.name}</td>
                            <td className='text-left px-1 py-1'>{product.pivot.qty}</td>
                            <td className='text-right px-1 py-1'>
                                ₦{new Intl.NumberFormat('en-US').format(product.pivot.selling_price * product.pivot.qty)}
                            </td>
                        </tr>
                    ))}

                    <tr className=' border-t-black border-y-1' style={{ fontSize: '12px' }}>
                            <td className='text-left px-2 py-1'></td>
                            <td className='text-left px-2 py-1 font-bold text-1xl'>SaaS Charges</td>
                            <td className='text-right px-1 py-1 font-bold text-1xl'>₦{new Intl.NumberFormat('en-US').format((3.5/100)*total)}</td>
                    </tr>
                   
                    <tr className=' border-t-black border-y-1' style={{ fontSize: '12px' }}>
                            <td className='text-left px-2 py-1'></td>
                            <td className='text-left px-2 py-1 font-bold text-1xl'>Sub Total</td>
                            <td className='text-right px-1 py-1 font-bold text-1xl'>₦{new Intl.NumberFormat('en-US').format(total)}</td>
                    </tr>
                    <tr className=' border-b-black border-y-1' style={{ fontSize: '12px' }}>
                            <td className='text-left px-2 py-1'></td>
                            <td className='text-left px-2 py-1 font-bold text-1xl'>Total + SaaS Charges</td>
                            <td className='text-right px-1 py-1 font-bold text-1xl'>₦{new Intl.NumberFormat('en-US').format(total+(3.5/100)*total)}</td>
                    </tr>
                    </tbody>
                </table>

                <canvas className='mx-auto' ref={inputRef} />
                <div className='font-bold font-medium text-center'> POWERED BY BiziPay © {new Date().getFullYear()}</div>
                <div className='font-bold font-medium text-center mx-auto'>www.bizipay.ng</div>
                        {/* <canvas ref={inputRef} /> */}
            </div>
        </div>

        <div className='my-6'>
        <button
        onClick={confirm}
            type="button"
            { ...processing && 'disabled'}

            className={`hover:shadow-form grid grid-cols-2 w-1/3 mx-auto items-center justify-content-center file:rounded-md  py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-green-300' : 'bg-green-500'}`}
        >{processing && (
                <div className="items-center">
                <ProgressSpinner style={{width: '30px', height: '30px', color:'red'}} strokeWidth="6" fill="none" animationDuration=".2s" />
        </div>
        )

        }
        <div className="items-center justify-center text-white">
            Confirm  Receipt
        </div>
        </button>
    </div>

        </>
    );
}
