import React from "react";
import './paliative.png'

 const  SideBarLogo = () => {
            return(
                <>
                     <img src="https://ktapi.scan-verify.com/logo/2.png" className='w-70 h-50'/>
                </>
            )
   };

export default SideBarLogo;
