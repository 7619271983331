import { useEffect,useRef, useState } from 'react';
import axios from '../../src/api'; // Adjust the path accordingly
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { RadioButton } from 'primereact/radiobutton';


import InputField from "../components/InputField";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

import $ from 'jquery';
import 'dropify/dist/js/dropify.min.js';
import 'dropify/dist/css/dropify.min.css';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { Dialog } from 'primereact/dialog';
import { Helmet } from 'react-helmet';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import Preloader from '../components/Preloader';
// import Staffs from '../components/Staffs';
import * as XLSX from 'xlsx';
import { FaExclamationCircle } from 'react-icons/fa';
import Banner from '../components/Banner';
import Modal from '../components/Modal';

export default function ApplyForm({ auth }) {
    const [customers, setCustomersData] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [shopdata, setShopData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const stepperRef = useRef(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [visible, setVisible] = useState(false);
    const [employ, setIsEmploy] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [preloading, setPreloading] = useState(false);
    const [selectedShop, setSelectedShop] = useState([]);
    const [data, setData] = useState([]);
    const [selectedCat, setSelectedCat] = useState('');
    const [catVal, setCatVal] = useState('');
    const [subCatVal, setSubCatVal] = useState('');
    const [subCatArray, setSubCatArray] = useState('');
    const [gender, setGender] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [agentFound, setAgentFound] = useState(false);
    const [accountMatched, setAccountMatched] = useState(false);
    const [status, setStatus] = useState('');
    const [isVerify, setIsVerify] = useState(false);
 

   

    

    // const [selectedLga, setSelectedLga] = useState();
    const [selectedLga, setSelectedLga] = useState([
        { "id": 1, "name": "Bakori" },
        { "id": 2, "name": "Batagarawa" },
        { "id": 3, "name": "Batsari" },
        { "id": 4, "name": "Baure" },
        { "id": 5, "name": "Bindawa" },
        { "id": 6, "name": "Charanchi" },
        { "id": 7, "name": "Dandume" },
        { "id": 8, "name": "Danja" },
        { "id": 9, "name": "Dan Musa" },
        { "id": 10, "name": "Daura" },
        { "id": 11, "name": "Dutsi" },
        { "id": 12, "name": "Dutsin-Ma" },
        { "id": 13, "name": "Faskari" },
        { "id": 14, "name": "Funtua" },
        { "id": 15, "name": "Ingawa" },
        { "id": 16, "name": "Jibia" },
        { "id": 17, "name": "Kafur" },
        { "id": 18, "name": "Kaita" },
        { "id": 19, "name": "Kankara" },
        { "id": 20, "name": "Kankia" },
        { "id": 21, "name": "Katsina" },
        { "id": 22, "name": "Kurfi" },
        { "id": 23, "name": "Kusada" },
        { "id": 24, "name": "Mai'Adua" },
        { "id": 25, "name": "Malumfashi" },
        { "id": 26, "name": "Mani" },
        { "id": 27, "name": "Mashi" },
        { "id": 28, "name": "Matazu" },
        { "id": 29, "name": "Musawa" },
        { "id": 30, "name": "Rimi" },
        { "id": 31, "name": "Sabuwa" },
        { "id": 32, "name": "Safana" },
        { "id": 33, "name": "Sandamu" },
        { "id": 34, "name": "Zango" }
      ]
      );
    const [catId, setCatId] = useState(null);
    const [formData, setFormData] = useState({
        app_type:'',
        full_name: '',
        address: '',
        contact: '',
        customer_type: '',
        psn: '',
        bvn:'',
        ward:'',
        agent_id:'',
        town:'',
        nin:'',
        lga:'',
        contact:'',
        email:'',
        mda:'',
        rank:'',
        level:'',
        dob:'',
        gender:'',
        passport:'',
        bizipay_account:'',
        moniepoint_account:'',
    
    });


    const [errorMessages, setErrorMessages] = useState({
        full_name: '',
        address: '',
        contact: '',
        customer_type: '',
        psn: '',
        nin: '',
        lga: '',
        ward: '',
        bvn: '',
        email: '',
        passport: '',
        bizipay_account: '',
        moniepoint_account: '',
    });
    
    
    const fileUploadRef = useRef(null); // Create a ref for the FileUpload component
    const [uploadedFiles, setUploadedFiles] = useState([]);

    // localStorage.setItem('token','10|WlwsrUGd8endOmy1gvy1ZLFR5BI2Mk7PVuiurjFxec840530')

    const onUpload = (event) => {
        // Add the uploaded files to the uploadedFiles state
        // const files = event.files;

        const inputElement = fileUploadRef.current.getInput(); // Get the input element using the ref
        const files = inputElement.files; // Access the FileList from the input
        // Update the uploaded files state
        setFormData((prevState) => ({
            ...prevState,
            passport: files[0]
        }));


        console.log('acual',files[0])
        console.log('elemt',inputElement)
        console.log('event',event)
        console.log("formData",formData.passport)

        };

    const customerType =[
        { "id": 1, "name": "Individual" },
        { "id": 2, "name": "Employee" },
    ]



    const applyType=[
        {'id': '', 'name': 'Choose Application Type'},
        {'id': 'new', 'name': 'New Registration'},
        {'id': 'existing', 'name': ' Existing Registration (update Bank details)'},
    ]

   
    const wardsData =[
        {"id": 1, "name": "Abadau/kagara"},
        {"id": 2, "name": "Abdallawa"},
        {"id": 3, "name": "Abukur"},
        {"id": 4, "name": "Agala"},
        {"id": 5, "name": "Agayawa"},
        {"id": 6, "name": "Ajiwa"},
        {"id": 7, "name": "Baawa"},
        {"id": 8, "name": "Babban Duhu 'a'"},
        {"id": 9, "name": "Babban Duhu 'b'"},
        {"id": 10, "name": "Babban Mutum"},
        {"id": 11, "name": "Bagagadi"},
        {"id": 12, "name": "Bagiwa"},
        {"id": 13, "name": "Bakiyawa"},
        {"id": 14, "name": "Bakori A"},
        {"id": 15, "name": "Bakori B"},
        {"id": 16, "name": "Bamble"},
        {"id": 17, "name": "Banye"},
        {"id": 18, "name": "Barawa"},
        {"id": 19, "name": "Barde/kwantakwaran"},
        {"id": 20, "name": "Bareruwa/ruruma"},
        {"id": 21, "name": "Barkiyya"},
        {"id": 22, "name": "Batagarawa A"},
        {"id": 23, "name": "Batagarawa B"},
        {"id": 24, "name": "Batsari"},
        {"id": 25, "name": "Bauranya 'a'"},
        {"id": 26, "name": "Bauranya 'b'"},
        {"id": 27, "name": "Baure"},
        {"id": 28, "name": "Baure"},
        {"id": 29, "name": "Baure 'a'"},
        {"id": 30, "name": "Baure 'b'"},
        {"id": 31, "name": "Bidore/yaya"},
        {"id": 32, "name": "Bindawa"},
        {"id": 33, "name": "Birchi"},
        {"id": 34, "name": "Boko"},
        {"id": 35, "name": "Borin Dawa"},
        {"id": 36, "name": "Bugaje"},
        {"id": 37, "name": "Bujawa/gewayau"},
        {"id": 38, "name": "Bumbum 'a'"},
        {"id": 39, "name": "Bumbum 'b'"},
        {"id": 40, "name": "Burdugau"},
        {"id": 41, "name": "Charanchi"},
        {"id": 42, "name": "Dabai"},
        {"id": 43, "name": "Dabawa"},
        {"id": 44, "name": "Damari"},
        {"id": 45, "name": "Dan Ali"},
        {"id": 46, "name": "Dan Alkima"},
        {"id": 47, "name": "Dan Aunai"},
        {"id": 48, "name": "Dan Murabu"},
        {"id": 49, "name": "Dan Musa A"},
        {"id": 50, "name": "Dan Musa B"},
        {"id": 51, "name": "Dan'maidaki"},
        {"id": 52, "name": "Dan. Alh/yangaiya"},
        {"id": 53, "name": "Dandagoro"},
        {"id": 54, "name": "Dandire 'b'"},
        {"id": 55, "name": "Dandire A"},
        {"id": 56, "name": "Dandume A"},
        {"id": 57, "name": "Dandume B"},
        {"id": 58, "name": "Dandutse"},
        {"id": 59, "name": "Daneji 'a'"},
        {"id": 60, "name": "Daneji 'b'"},
        {"id": 61, "name": "Dangani"},
        {"id": 62, "name": "Danja A"},
        {"id": 63, "name": "Danja B"},
        {"id": 64, "name": "Danjanku/karachi"},
        {"id": 65, "name": "Dankaba"},
        {"id": 66, "name": "Dankama"},
        {"id": 67, "name": "Dansarai"},
        {"id": 68, "name": "Dantankari"},
        {"id": 69, "name": "Dantutture"},
        {"id": 70, "name": "Danyashe"},
        {"id": 71, "name": "Dara"},
        {"id": 72, "name": "Dargage"},
        {"id": 73, "name": "Darini/magaji/abu"},
        {"id": 74, "name": "Daudawa"},
        {"id": 75, "name": "Daunaka/b.kwari"},
        {"id": 76, "name": "Dawan Musa"},
        {"id": 77, "name": "Dayi"},
        {"id": 78, "name": "Dissi"},
        {"id": 79, "name": "Doguru 'a'"},
        {"id": 80, "name": "Doguru 'b'"},
        {"id": 81, "name": "Doka"},
        {"id": 82, "name": "Doro"},
        {"id": 83, "name": "Dudunni"},
        {"id": 84, "name": "Dugul"},
        {"id": 85, "name": "Dugun Mu'azu"},
        {"id": 86, "name": "Dukke"},
        {"id": 87, "name": "Dutsi A"},
        {"id": 88, "name": "Dutsi B"},
        {"id": 89, "name": "Dutsin Kura/kanya"},
        {"id": 90, "name": "Dutsin-ma A"},
        {"id": 91, "name": "Dutsin-ma B"},
        {"id": 92, "name": "Duwan/makau"},
        {"id": 93, "name": "Fago 'a'"},
        {"id": 94, "name": "Fago 'b'"},
        {"id": 95, "name": "Fardami"},
        {"id": 96, "name": "Farfaru"},
        {"id": 97, "name": "Faru"},
        {"id": 98, "name": "Faru/dallaji"},
        {"id": 99, "name": "Faskari"},
        {"id": 100, "name": "G/baure/mallamawa"},

        {"id": 101, "name": "Gachi"},
        {"id": 102, "name": "Gafiya"},
        {"id": 103, "name": "Gaiwa"},
        {"id": 104, "name": "Galadima 'a'"},
        {"id": 105, "name": "Galadima 'b'"},
        {"id": 106, "name": "Gallu"},
        {"id": 107, "name": "Gamji"},
        {"id": 108, "name": "Gamzago"},
        {"id": 109, "name": "Gangara"},
        {"id": 110, "name": "Ganuwa"},
        {"id": 111, "name": "Garagi"},
        {"id": 112, "name": "Garki"},
        {"id": 113, "name": "Garni"},
        {"id": 114, "name": "Garu"},
        {"id": 115, "name": "Gatakawa S/gari/mabai"},
        {"id": 116, "name": "Gazari"},
        {"id": 117, "name": "Gingin"},
        {"id": 118, "name": "Giremawa"},
        {"id": 119, "name": "Girka"},
        {"id": 120, "name": "Gorar Dansaka"},
        {"id": 121, "name": "Goya"},
        {"id": 122, "name": "Gozaki"},
        {"id": 123, "name": "Guga"},
        {"id": 124, "name": "Gwamba"},
        {"id": 125, "name": "Gwarjo"},
        {"id": 126, "name": "Hamcheta"},
        {"id": 127, "name": "Hui"},
        {"id": 128, "name": "Hurya"},
        {"id": 129, "name": "Ingawa"},
        {"id": 130, "name": "Jani"},
        {"id": 131, "name": "Jargaba"},
        {"id": 132, "name": "Jiba"},
        {"id": 133, "name": "Jibawa/r,bade"},
        {"id": 134, "name": "Jibia A"},
        {"id": 135, "name": "Jibia B"},
        {"id": 136, "name": "Jigawa"},
        {"id": 137, "name": "Jikamshi"},
        {"id": 138, "name": "Jino"},
        {"id": 139, "name": "Jobe/kandawa"},
        {"id": 140, "name": "Kabomo"},
        {"id": 141, "name": "Kadandani"},
        {"id": 142, "name": "Kafin Dangi/fakuwa"},
        {"id": 143, "name": "Kafinsoli"},
        {"id": 144, "name": "Kafur"},
        {"id": 145, "name": "Kagara/faski"},
        {"id": 146, "name": "Kagare"},
        {"id": 147, "name": "Kahutu A"},
        {"id": 148, "name": "Kahutu B"},
        {"id": 149, "name": "Kaikai"},
        {"id": 150, "name": "Kaita"},
        {"id": 151, "name": "Kakumi"},
        {"id": 152, "name": "Kamri"},
        {"id": 153, "name": "Kanda"},
        {"id": 154, "name": "Kandarawa"},
        {"id": 155, "name": "Kandawa"},
        {"id": 156, "name": "Kangiwa"},
        {"id": 157, "name": "Kankara A&b"},
        {"id": 158, "name": "Karaduwa"},
        {"id": 159, "name": "Karare"},
        {"id": 160, "name": "Karau"},
        {"id": 161, "name": "Karfi"},
        {"id": 162, "name": "Karkarku"},
        {"id": 163, "name": "Karofi A"},
        {"id": 164, "name": "Karofi B"},
        {"id": 165, "name": "Katsayal"},
        {"id": 166, "name": "Kawarin Kudi"},
        {"id": 167, "name": "Kawarin Malawamai"},
        {"id": 168, "name": "Kayauki"},
        {"id": 169, "name": "Kayawa"},
        {"id": 170, "name": "Kira"},
        {"id": 171, "name": "Koda"},
        {"id": 172, "name": "Kofa"},
        {"id": 173, "name": "Kogari"},
        {"id": 174, "name": "Koza"},
        {"id": 175, "name": "Kukasheka"},
        {"id": 176, "name": "Kuki A"},
        {"id": 177, "name": "Kuki B"},
        {"id": 178, "name": "Kunduru/gyaza"},
        {"id": 179, "name": "Kurami/yankwani"},
        {"id": 180, "name": "Kuraye"},
        {"id": 181, "name": "Kurfeji/yankaura"},
        {"id": 182, "name": "Kurfi 'a'"},
        {"id": 183, "name": "Kurfi 'b'"},
        {"id": 184, "name": "Kurkujan 'a'"},
        {"id": 185, "name": "Kurkujan 'b'"},
        {"id": 186, "name": "Kusa"},
        {"id": 187, "name": "Kusada"},
        {"id": 188, "name": "Kusugu"},
        {"id": 189, "name": "Kutawa"},
        {"id": 190, "name": "Kwasarawa"},
        {"id": 191, "name": "Kwatta"},
        {"id": 192, "name": "Machika"},
        {"id": 193, "name": "Machika"},
        {"id": 194, "name": "Madobi A"},
        {"id": 195, "name": "Madobi B"},
        {"id": 196, "name": "Madogara"},
        {"id": 197, "name": "Magaji Wando A"},
        {"id": 198, "name": "Magaji Wando B"},
        {"id": 199, "name": "Magam/tsa"},
        {"id": 200, "name": "Magami"},


        {"id": 201, "name": "Mahuta"},
        {"id": 202, "name": "Mahuta A"},
        {"id": 203, "name": "Mahuta B"},
        {"id": 204, "name": "Mahuta C"},
        {"id": 205, "name": "Mai Bara"},
        {"id": 206, "name": "Mai Dabino A"},
        {"id": 207, "name": "Mai Dabino B"},
        {"id": 208, "name": "Mai Gamji"},
        {"id": 209, "name": "Mai Koni 'a'"},
        {"id": 210, "name": "Mai Koni 'b'"},
        {"id": 211, "name": "Mai'adua 'a'"},
        {"id": 212, "name": "Mai'adua 'b'"},
        {"id": 213, "name": "Mai'adua 'c'"},
        {"id": 214, "name": "Maibakko"},
        {"id": 215, "name": "Maigora"},
        {"id": 216, "name": "Mairuwa"},
        {"id": 217, "name": "Majen Wayya"},
        {"id": 218, "name": "Majengobir"},
        {"id": 219, "name": "Makaurachi"},
        {"id": 220, "name": "Makera"},
        {"id": 221, "name": "Makera"},
        {"id": 222, "name": "Makurda"},
        {"id": 223, "name": "Malum Fashi 'a'"},
        {"id": 224, "name": "Malum Fashi 'b'"},
        {"id": 225, "name": "Manawa"},
        {"id": 226, "name": "Mani"},
        {"id": 227, "name": "Manomawa/kafi"},
        {"id": 228, "name": "Mara"},
        {"id": 229, "name": "Masabo/kurabau"},
        {"id": 230, "name": "Masari"},
        {"id": 231, "name": "Mashi"},
        {"id": 232, "name": "Maska"},
        {"id": 233, "name": "Matazu 'a'"},
        {"id": 234, "name": "Matazu 'b'"},
        {"id": 235, "name": "Matsai"},
        {"id": 236, "name": "Mawashi"},
        {"id": 237, "name": "Mazanya/magama"},
        {"id": 238, "name": "Mazoji 'a'"},
        {"id": 239, "name": "Mazoji 'b'"},
        {"id": 240, "name": "Mazoji A"},
        {"id": 241, "name": "Mazoji B"},
        {"id": 242, "name": "Muduri"},
        {"id": 243, "name": "Muduru"},
        {"id": 244, "name": "Musawa"},
        {"id": 245, "name": "Na-alma"},
        {"id": 246, "name": "Nasarawa"},
        {"id": 247, "name": "Natsalle"},
        {"id": 248, "name": "Pauwa A&b"},
        {"id": 249, "name": "Radda"},
        {"id": 250, "name": "Rade 'a'"},
        {"id": 251, "name": "Rade 'b'"},
        {"id": 252, "name": "Rafin Iwa"},
        {"id": 253, "name": "Rawan Sanyi"},
        {"id": 254, "name": "Rawayau 'a'"},
        {"id": 255, "name": "Rawayau 'b'"},
        {"id": 256, "name": "Remawa/iyatawa"},
        {"id": 257, "name": "Riko"},
        {"id": 258, "name": "Rimaye"},
        {"id": 259, "name": "Rimi"},
        {"id": 260, "name": "Rinjin Idi"},
        {"id": 261, "name": "Rogogo/cidari"},
        {"id": 262, "name": "Ruma"},
        {"id": 263, "name": "Runka 'a'"},
        {"id": 264, "name": "Runka 'b'"},
        {"id": 265, "name": "Ruwan Godiya"},
        {"id": 266, "name": "Ruwankaya A"},
        {"id": 267, "name": "Ruwankaya B"},
        {"id": 268, "name": "S/rijiya"},
        {"id": 269, "name": "Sabon Gari"},
        {"id": 270, "name": "Sabon Gari"},
        {"id": 271, "name": "Sabon Garin/alarain"},
        {"id": 272, "name": "Sabonlayi/galadima"},
        {"id": 273, "name": "Sabuwa 'a'"},
        {"id": 274, "name": "Sabuwa 'b'"},
        {"id": 275, "name": "Sabuwar Kasa"},
        {"id": 276, "name": "Safana"},
        {"id": 277, "name": "Safana"},
        {"id": 278, "name": "Sandamu"},
        {"id": 279, "name": "Sara"},
        {"id": 280, "name": "Sarkin Yara A"},
        {"id": 281, "name": "Sarkin Yara B"},
        {"id": 282, "name": "Sayau"},
        {"id": 283, "name": "Sayaya"},
        {"id": 284, "name": "Shema"},
        {"id": 285, "name": "Sheme"},
        {"id": 286, "name": "Shibdawa"},
        {"id": 287, "name": "Shinkafi 'a'"},
        {"id": 288, "name": "Shinkafi 'b'"},
        {"id": 289, "name": "Sirika A"},
        {"id": 290, "name": "Sirika B"},
        {"id": 291, "name": "Sonkaya"},
        {"id": 292, "name": "Sukuntuni"},
        {"id": 293, "name": "Tafashiya/nasarawa"},
        {"id": 294, "name": "Tafoki"},
        {"id": 295, "name": "Tama/daye"},
        {"id": 296, "name": "Tamilo 'a'"},
        {"id": 297, "name": "Tamilo 'b'"},
        {"id": 298, "name": "Tandama"},
        {"id": 299, "name": "Taramnawa/bare"},
        {"id": 300, "name": "Tsagem/takusheyi"},

        {"id": 301, "name": "Tsagero"},
        {"id": 302, "name": "Tsakatsa"},
        {"id": 303, "name": "Tsangamawa"},
        {"id": 304, "name": "Tsanni"},
        {"id": 305, "name": "Tsaskiya"},
        {"id": 306, "name": "Tsauri 'a'"},
        {"id": 307, "name": "Tsauri 'b'"},
        {"id": 308, "name": "Tsiga"},
        {"id": 309, "name": "Tudun Iya"},
        {"id": 310, "name": "Tudun Wada"},
        {"id": 311, "name": "Tuge"},
        {"id": 312, "name": "Tumburkai A"},
        {"id": 313, "name": "Tumburkai B"},
        {"id": 314, "name": "Ubandawaki A"},
        {"id": 315, "name": "Unbadawaki B"},
        {"id": 316, "name": "Ung Ibrahim"},
        {"id": 317, "name": "Unguwar Musa"},
        {"id": 318, "name": "Unguwar Rabiu"},
        {"id": 319, "name": "Unguwar Rai"},
        {"id": 320, "name": "Wagini"},
        {"id": 321, "name": "Wakili Kudu Iii"},
        {"id": 322, "name": "Wakilin Arewa (a)"},
        {"id": 323, "name": "Wakilin Arewa (b)"},
        {"id": 324, "name": "Wakilin Gabas I"},
        {"id": 325, "name": "Wakilin Gabas Ii"},
        {"id": 326, "name": "Wakilin Kudu I"},
        {"id": 327, "name": "Wakilin Kudu Ii"},
        {"id": 328, "name": "Wakilin Yamma 1"},
        {"id": 329, "name": "Wakilin Yamma 1i"},
        {"id": 330, "name": "Wawar Kaza"},
        {"id": 331, "name": "Wurma 'a'"},
        {"id": 332, "name": "Wurma 'b'"},
        {"id": 333, "name": "Yaba"},
        {"id": 334, "name": "Yakaji A"},
        {"id": 335, "name": "Yakaji B"},
        {"id": 336, "name": "Yamel A"},
        {"id": 337, "name": "Yamel B"},
        {"id": 338, "name": "Yan-tumaki A"},
        {"id": 339, "name": "Yan-tumaki B"},
        {"id": 340, "name": "Yandaki"},
        {"id": 341, "name": "Yandoma"},
        {"id": 342, "name": "Yanduna"},
        {"id": 343, "name": "Yangaiya"},
        {"id": 344, "name": "Yangora"},
        {"id": 345, "name": "Yanhoho"},
        {"id": 346, "name": "Yankara"},
        {"id": 347, "name": "Yanmaulu"},
        {"id": 348, "name": "Yaradau/tabanni"},
        {"id": 349, "name": "Yardaje"},
        {"id": 350, "name": "Yargamji"},
        {"id": 351, "name": "Yari Bori"},
        {"id": 352, "name": "Yarmalamai"},
        {"id": 353, "name": "Yarmama"},
        {"id": 354, "name": "Yartalata/rigoji"},
        {"id": 355, "name": "Yashe 'a"},
        {"id": 356, "name": "Yashe 'b'"},
        {"id": 357, "name": "Yauyau/mallamawa"},
        {"id": 358, "name": "Zakka 'a'"},
        {"id": 359, "name": "Zakka 'b'"},
        {"id": 360, "name": "Zango"}

    ]
    

   



    const handleManagerChange = (e) => {
        const { value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            manager_id: value,
        }));
        setSelectedShop(value.id)

    };



    const handleSubCategoryChange = (e) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            subCategory: value,
        }));


    };


    const handleUnitChange = (e) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            unit: value,
        }));

    };


    const handleEmployChange = (name,value) => {
        setFormData((prevState) => ({
            ...prevState,
            customer_type: value,
        }));

    };

// useEffect(() => {
//     try {
//         let response = axios.get('https://ktapi.scan-verify.com/api/sendSms');

//         console.log("sms",response.data)
//       } catch (error) {
//         console.error("Error fetching data: ", error);
//         return [];
//       }
// }, []);

useEffect(() => {

        if(formData?.customer_type?.name == customerType[1]?.name){
            setIsEmploy(true);
        }else{
            setIsEmploy(false);
        }
}, [formData.customer_type]);

    const handleInputChange = (field,value) => {
        
        setFormData((prevState) => ({
            ...prevState,
            [field]: value
        }));
    };

useEffect(() => {
    setIsEmploy(true)
    setFormData((prevState) => ({
        ...prevState,   
        customer_type: customerType[0]
    }))
},[]);

useEffect(() => {
    
    if (formData?.psn?.length == 6) {
    
        verifyPSN()
    
    }
    
}, [formData.psn]);

// useEffect(() => {
//     if (formData?.agent_id?.length == 10 || formData?.agent_id?.length == 11 ) {
//         verifyAgent();
//     }
// }, [formData.agent_id]);

useEffect(() => {
    if (formData?.moniepoint_account?.length == 10) {
        verifyMoniepointAccount();
    }
}, [formData.moniepoint_account]);

 
    const fetchStates = async () => {
        try {
            const response = await axios.get('https://nga-states-lga.onrender.com/?state=Katsina');
            return response.data.lga;
        } catch (error) {
            return [];
        }
    };





    const toast = useRef(null);

    const showSuccess = (severity,summary,message) => {
        toast.current.show({severity:severity, summary: summary, detail:message, life: 10000});
    }
        const accept = () => {
                if (toast.current) {
                    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 10000 });
                } else {
                    console.error('Toast reference is null');
                }
            };

            const reject = () => {
                if (toast.current) {
                    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 10000 });
                } else {
                    console.error('Toast reference is null');
                }
            };

            const confirm1 = () => {
                confirmDialog({
                    group: 'headless',
                    message: 'Are you sure you want to delete?',
                    header: 'Confirmation',
                    icon: 'pi pi-trash',
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();

        console.log(formData)
        let errorMessages = {};

        if(!formData.full_name || formData.full_name.trim() === ''){
            errorMessages.full_name = 'Full name is required';
        }

        if(!formData.address || formData.address.trim() === ''){
            errorMessages.address = 'Address is required';
        }

        if(!formData.contact || formData.contact.trim() === ''){
            errorMessages.contact = 'Contact is required';
        }

        if(formData.contact && formData.contact.length > 11){
            errorMessages.contact = 'Contact must be 11 digits or less';
        }

     

        if(!formData.psn || formData.psn.trim() === ''){
            errorMessages.psn = 'PSN is required';
        }

        if(!formData.nin || formData.nin.trim() === ''){
            errorMessages.nin = 'NIN is required';
        }

        if(formData.nin && formData.nin.length > 11){
            errorMessages.nin = 'NIN must be 11 digits or less';
        }

        if(!formData.lga || !formData.lga.name){
            errorMessages.lga = 'LGA is required';
        }

        if(!formData.ward || !formData.ward.name){
            errorMessages.ward = 'Ward is required';
        }


        if(!formData.bvn || formData.bvn.trim() === ''){
            errorMessages.bvn = 'BVN is required';
        }

        if(formData.bvn && formData.bvn.length > 11){
            errorMessages.bvn = 'BVN must be 11 digits or less';
        }

        if(!formData.email || formData.email.trim() === ''){
            errorMessages.email = 'Email is required';
        }

       

        if(!formData.passport){
            errorMessages.passport = 'Passport is required';
        }

        if(!formData.bizipay_account || formData.bizipay_account.trim() === ''){
            errorMessages.bizipay_account = 'Bizipay account is required';
        }

        if(!formData.moniepoint_account || formData.moniepoint_account.trim() === ''){
            errorMessages.moniepoint_account = 'Moniepoint account is required';
        
        }

        if(Object.keys(errorMessages).length > 0){
         
            console.log(errorMessages)
            setErrorMessages(errorMessages);
            return;
        }

        console.log(errorMessages)
        // Append each field from formData to the FormData object
        form.append('full_name', formData.full_name);
        form.append('address', formData.address);
        form.append('contact', formData.contact);
        form.append('customer_type', formData.customer_type?.name);
        form.append('psn', formData.psn);
        form.append('nin', formData.nin);
        form.append('lga', formData.lga?.name);
        form.append('ward', formData.ward?.name);
        form.append('agent_id', formData.agent_id);
        form.append('town', formData.town);
        form.append('bvn', formData.bvn);
        form.append('email', formData.email);
        form.append('mda', 'mdas');
        form.append('rank', formData.rank);
        form.append('level', formData.level);
          // Format the date of birth
        const dob = new Date(formData.dob? formData.dob: '2024-04-20');
        const formattedDob = dob?.toISOString().split('T')[0]; // Format to YYYY-MM-DD
        form.append('dob', formattedDob);

        form.append('gender', gender);
        form.append('passport', formData.passport); // Assuming passport is a File object
        form.append('bizipay_account', formData.bizipay_account);
        form.append('moniepoint_account', formData.moniepoint_account);

        
        setProcessing(true);
        try {
            const response = await axios.post('https://ktapi.scan-verify.com/api/customer/apply',form, {
                headers: {
                   'Content-Type': 'multipart/form-data'
                }
            });
            
            // console.log('Product added:', response.data);

            setProcessing(false);
            if(response.data.success == true){

                             
                
                setFormData([]);

         
                showSuccess('success',"Customer",response.data.message)
                setInterval(() => {
                    // window.location.reload()
                },3000)
            }else{
                showSuccess('error',"Customer",response.data.message)
            }
        } catch (error) {
            setProcessing(false);
            showSuccess('error',"Shop",error?.request?.response)
            // console.error('Error adding product:', error?.request?.response);
        }
    };



    const handleUpdateAccount = async () => {
      

        const form = new FormData();
       
        let errorMessages = {};

        if(!formData.psn || formData.psn.trim() === ''){
            errorMessages.psn = 'PSN is required';
        }

        if(!formData.moniepoint_account || formData.moniepoint_account.trim() === ''){
            errorMessages.moniepoint_account = 'Moniepoint account is required';
        }

        if(Object.keys(errorMessages).length > 0){
            showSuccess('error',"Empty",errorMessages.psn)
            showSuccess('error',"Empty",errorMessages.moniepoint_account)
            setErrorMessages(errorMessages);
            return;
        }

        
        // Append each field from formData to the FormData object
        form.append('psn', formData.psn);
        form.append('moniepoint_account', formData.moniepoint_account);

        setProcessing(true);
        try {
            const response = await axios.post('https://ktapi.scan-verify.com/api/customer/update-account',form, {
                headers: {
                   'Content-Type': 'multipart/form-data'
                }
            });

            setProcessing(false);
            if(response.data.success === true){

                // setFormData([]);
                showSuccess('success',"Customer",response.data.message)

            }else{
                showSuccess('error',"Customer",response.data.message)
            }
        } catch (error) {
            setProcessing(false);
            showSuccess('error',"Shop",error?.request?.response)
        }
    };

      


    const dropifyRef = useRef(null);

    useEffect(() => {
        // Initialize Dropify
        $(dropifyRef.current).dropify();

        // Clean up Dropify instance on unmount
        return () => {
            if (dropifyRef.current) {
                $(dropifyRef.current).dropify('destroy');
            }
        };
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevState) => ({
            ...prevState,
            image: file
        }));

    };


    const verifyPSN = async () => {
        setProcessing(true); // Start processing

        try {
            // Make the GET request to verify the sale
            const response = await axios.get(`https://ktapi.scan-verify.com/api/customer/verify/${formData.psn}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Handle the successful response here
            if (response.data.success == true) {

                console.log('Error', response.data.message)
                if(formData?.app_type?.id =='new'){
                    showSuccess('success', 'PSN Verify', response?.data?.message);
                }else{
                    showSuccess('success', 'PSN Verify', "PSN is valid registered in palliative shop");
                }
              
                setData(response.data.psn)
                    setFormData((prevState) => ({
                        ...prevState,
                        full_name: response.data?.psn?.staff_name,
                    }));

                setIsVerify(true);

            } else {
                setNotFound(true)
                // showSuccess('error', 'PSN Verify', "PSN Not found");
                if(formData?.app_type?.id =='new'){
                    showSuccess('error', 'PSN Verify', response.data.message);
                }else{
                      showSuccess('error', 'PSN Verify', response.data.message);
                }
                
            }

        } catch (error) {
            // Handle errors and display a relevant message
            console.error('Error PSN Verify:', error);
            showSuccess('error', 'PSN Verify', error.response);
        } finally {
            // Ensure processing is stopped regardless of the outcome
            setProcessing(false);
        }
    };

 
    const handleAppTypeChange = (e) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            app_type: value
        }));

    };

    useEffect(() => {
        setVisible(true)
        setStatus(formData?.app_type?.id)
      
    }, [formData?.app_type, status]);
    


    const verifyMoniepointAccount = async () => {
 
        try {
            // Send the GET request to Paystack
            const response = await fetch(`https://api.paystack.co/bank/resolve?account_number=${formData.moniepoint_account}&bank_code=50515`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_Bank_API_KEY}`,
                },
                timeout: 30000  // Timeout in milliseconds    
            });
            const data = await response.json();
            
            // Ensure content has the 'data' key and that the request was successful
            if (data.status === true && data.data.account_name) {
                console.log('Success account resolve:', data);
                // Extract the name safely
                const nameParts = data.data.account_name.split(' ');

                // alert(`nameParts: ${nameParts}`);

                let fName = '';
                let lName = '';
                // alert(`nameParts: ${nameParts}`);
                if(nameParts.length > 2) {
                    fName = nameParts[0] + ' ' + nameParts[1];
                    lName = nameParts[2];
                    // alert(`fName: ${fName}, lName: ${lName}`);
                } else {
                    fName = nameParts[0];
                    lName = nameParts[1];
                    // alert(`fName: ${fName}, lName: ${lName}`);
                }
                
                
                const fullNameArray = formData.full_name.toLowerCase().split(' ');
                if(fullNameArray == '') {
                    showSuccess('error', 'Account Verification', 'PSN must be verified, then name retrieve');
                }
                const isMatch = fullNameArray.some((name) => {
                    const foundName = name.length > 2 ? name.substring(0, 3) : name;
                    return [fName, lName].some((n) => n.toLowerCase().includes(foundName));
                });
                if(isMatch) {
                    showSuccess('success', 'Moniepoint Account Verification', `Name  matched ${fName} ${lName}`);
                   setAccountMatched(true)
                    return `${fName} ${lName}`;
                } else {

                    // alert(`fName: ${fName}, lName: ${lName}`);
                    showSuccess('error', 'Moniepoint Account Verification', `Name doesn't matched  ${formData?.full_name}`);
                    setAccountMatched(true)
                    return null;
                }
            } else {
                showSuccess('error', 'Account Verification', 'This account is not Valid Moniepoint Account');
                return null;  // Fallback in case no valid name is found
            }
        } catch (error) {
            // Handle the exception
            if (error.response) {
                const { status, data } = error.response;
                // You could log the error or return a message if needed
                // console.error(`HTTP Error ${status}: ${data}`);
            } else {
                // console.error("Request failed:", error.message);
            }
            return null;  // Return empty string on error
        }
    }

    const footer = <Button label="Close" className="p-button-raised p-button-success" onClick={() => setVisible(false)} />;

    return (


<div className="container w-full my-5 mb-5 rounded-2xl mx-auto ">

<Helmet>
        <title>Register | Jigawa Palliative Form</title>
        <meta name="address" content="Palliative by Jigawa State Gov't by Danmodi." />

        <meta property="og:title" content="About Us - My App" />
        <meta property="og:address" content="Learn more about us on this page." />
</Helmet>

<Toast ref={toast} />
            <ConfirmDialog
                group="headless"
                content={({ headerRef, contentRef, footerRef, hide, message }) => (
                    <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
                        <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                            <i className="pi pi-question text-5xl"></i>
                        </div>
                        <span className="font-bold text-2xl block mb-2 mt-4" ref={headerRef}>
                            {message.header}
                        </span>
                        <p className="mb-0" ref={contentRef}>
                            {message.message}
                        </p>
                        <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>
                            <Button
                                label="Save"
                                onClick={(event) => {
                                    hide(event);
                                    accept();
                                }}
                                className="w-8rem"
                            ></Button>
                            <Button
                                label="Cancel"
                                outlined
                                onClick={(event) => {
                                    hide(event);
                                    reject();
                                }}
                                className="w-8rem"
                            ></Button>
                        </div>
                    </div>
                )}
            />

        <Modal
          visible={visible}
          setVisible={setVisible}
          title="Announcement"
          footer={footer}
        >
            <p className="text-center text-sm font-light">
                <span className="font-bold text-red-500">Disclaimer:</span> Jigawa State Palliative Project Registration
                <br />
                The Jigawa State Government and Bizi Mobile Cashless Consultant Ltd. strictly prohibit any agent from collecting registration fees for the Palliative Project. Registration is free. Beneficiaries can only deposit ₦1000 into their Moniepoint account for ATM card activation. Report any unauthorized fee demands immediately.
            </p>
        </Modal>

{preloading && (<Preloader />)}
        <div className=" justify-content-center">

            <div className="mx-auto md:w-1/2 sm:max-w-max-[50%] h-full bg-white px-3.5 py-2.5 rounded-md justify-between ">
            <Toast ref={toast} />

            <div className="bg-gradient-to-r  from-green-400 to-green-500 px-2 rounded-2xl mx-auto mb-5">
              <Banner /> 
            </div>
            {/* <div className="bg-gradient-to-r from-green-400 to-green-500 p-5 rounded-2xl mx-auto mb-5">
                <h1 className=" font-bold text-2xl text-center mx-auto place-self-center mt-5 text-white">Jigawa State Palliative Shop Application Form</h1>
                <h5 className="text-1xl place-self-center my-2 text-white mx-auto text-center mb-5">Initiated by Gov. Umar Namadi Danmodi</h5>
            </div> */}


            <form onSubmit={handleSubmit}>

                <div className="justify-content-center mx-auto md:w-full sm:w-1/2 h-auto">
                    <Stepper ref={stepperRef} style={{ flexBasis: '20rem' }}>
                        <StepperPanel header="Status">
                            <div className="h-auto">
                                <div className="border-2 border-dashed surface-border border-round px-4 py-6 w-full surface-ground  justify-content-center align-items-center font-medium">                                                                
                                  
                                        <>
                                            <label className="mb-3 block text-base font-medium font-bold text-[#07074D]">
                                                Personal Service Number (PSN)
                                            </label>
                                            <input
                                                id="psn"
                                                type="text"
                                            
                                                name="psn"
                                                minLength={1}
                                                maxLength={6}
                                                required={false}
                                                placeholder="PSN Number"
                                                value={formData?.psn}
                                                onChange={(e)=>{handleInputChange('psn',e.target.value)}}
                                                className="rounded-md py-3 px-5 border w-full border-green-500 bg-white  text-base font-medium font-bold text-[#e40808] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                            />
                                            {/* <span className="text-sm text-red-500">New Registration is suspended currently till further notice</span> */}
                                             {errorMessages?.psn && <span className="text-sm text-red-500">{errorMessages?.psn}</span>}
                                        </>
                                   
                                    
                               
                                </div>
                            </div>
               
                                <div className="flex pt-4 justify-content-end">
                                    {isVerify ? (
                                        <Button
                                            label="Next"
                                            icon="pi pi-arrow-right"
                                            iconPos="right"
                                            onClick={() => stepperRef.current.nextCallback()}
                                        />
                                    ) : (
                                        <Button
                                            label="Next"
                                            icon="pi pi-arrow-right"
                                            iconPos="right"
                                            onClick={() =>
                                                showSuccess(
                                                    'error',
                                                    'PSN Error',
                                                    'Please Verify Your Account PSN'
                                                )
                                            }
                                        />
                                    )}
                                </div>
                        
                               
                                 
                         
                        </StepperPanel>

                  
                        <StepperPanel header="Bio Data">
                            <div className="flex flex-column h-auto my-6">
                                <div className="border-2 border-dashed surface-border border-round px-4 py-6 w-full surface-ground  justify-content-center align-items-center font-medium">
                                                                
                                <InputField
                                    id="full_name"
                                    label="Full Name"
                                    type="text"
                                    name="full_name"
                                    required={true}
                                    disabled={true}
                                    placeholder="Full Name"
                                    value={formData?.full_name}
                                    onChange={(e)=>{handleInputChange('full_name',e.target.value)}}
                                    error={errorMessages?.full_name}
                                />


                                <InputField
                                    id="contact"
                                    label="Phone Number"
                                    type="number"
                                    name="contact"
                                    required={true}
                                    placeholder="Phone Number"
                                    value={formData.contact}
                                    onChange={(e)=>{handleInputChange('contact',e.target.value)}}
                                    error={errorMessages?.contact}
                                />



                                <InputField
                                    id="email"
                                    label="Email"
                                    type="email"
                                    name="email"
                                    required={true}
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={(e)=>{handleInputChange('email',e.target.value)}}
                                    error={errorMessages?.email}
                                />


                                <InputField
                                    id="nin"
                                    label="National Identity Number"
                                    type="number"
                                    name="nin"
                                    minLength={1}
                                    maxLength={11}
                                    required={true}
                                    placeholder="National Identity Number"
                                    value={formData.nin}
                                    onChange={(e)=>{handleInputChange('nin',e.target.value)}}
                                    error={errorMessages?.nin}
                                />


                                <label htmlFor="dob" className="my-3 block font-bold text-base font-medium  text-[#07074D]">
                                        Choose Date of Birth
                                </label>

                                <Calendar inputClassName='px-3 py-2 border border-green-500 h-1/3'     required={true} panelStyle={{ background:'#ffff9', color:'green'}} placeholder='Choose date of Birth' nextIcon={true}  iconPos='left' viewDate={true} todayButtonClassName='bg-green-900 text-white' value={formData.dob} onChange={(e) => handleInputChange('dob',e.value)} showIcon error={errorMessages?.dob}  />


                                <div className="flex flex-wrap gap-3 mt-4">
                                    <label htmlFor="dob" className="my-3 block font-bold text-base font-medium text-[#07074D]">
                                            Choose Gender
                                    </label><br/>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="gender1" name="gender" required={true} style={{color:'green'}} value="Male" onChange={(e) => setGender(e.value)} checked={gender === 'Male'} error={errorMessages?.gender} />
                                        <label htmlFor="gender1" className="ml-2">Male</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="gender2" name="gender" color='green'     required={true}  className='text-green-400'  value="Female" onChange={(e) => setGender(e.value)} checked={gender === 'Female'} error={errorMessages?.gender} />
                                        <label htmlFor="gender2" className="ml-2">Female</label>
                                    </div>
                                </div>



                                <div className="mb-5">
                                    <label htmlFor="address" className="mb-3 block text-base font-medium font-bold text-[#07074D]">
                                    Address
                                    </label>
                                    <textarea
                                        id="address"
                                        name="address"
                                        required={true}
                                        onChange={(e)=>{ handleInputChange('address',e.target.value)}}
                                        defaultValue={formData.address}
                                        className="w-full rounded-md border border-green-500 bg-white py-3 px-5 text-base font-medium text-[#26ad38] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    ></textarea>
                                </div>


                                    <label htmlFor="cat" className="my-3 block font-bold text-base font-medium text-[#07074D]">
                                            Select LGA
                                    </label>
                                    <div className="card flex justify-content-center w-full  my-3">
                                            <Dropdown filter={true}     required={true}  placeholder='Select LGA'  inputId="dd-state" value={formData.lga} optionLabel='name' onChange={(e)=> {handleInputChange('lga',e.target.value) }} options={selectedLga}  className="w-full text-green-500 border border-green-500 px-4 py-2" error={errorMessages?.lga} /><br/>
                                            {errorMessages?.lga && <span className="text-sm text-red-500">{errorMessages?.lga}</span>}
                                    </div>



                                <label htmlFor="cat" className="my-3 block font-bold text-base font-medium text-[#07074D]">
                                        Select Ward (Polling Units)
                                </label>
                                <div className="card flex justify-content-center w-full  my-3">
                                        <Dropdown filter={true}       required={true} inputId="dd-state" value={formData.ward} placeholder='Select Ward' onChange={(e)=> {handleInputChange('ward',e.target.value) }} options={wardsData} optionLabel='name'  className="w-full text-green-500 border border-green-500 px-4 py-2" error={errorMessages?.ward} /><br/>
                                        {errorMessages?.ward && <span className="text-sm text-red-500">{errorMessages?.ward}</span>}
                                </div>





                            <div className="card">
                                        <label htmlFor="cat" className="my-3 block font-bold text-base font-medium text-[#07074D]">
                                            Upload your Photo
                                    </label>
                                <FileUpload
                                  chooseLabel='Upload Photo only PNG,JPEG,JPG,max file size of 1MB(*)'

                                  uploadLabel='Upload your Passport(Photo)'
                                    ref={fileUploadRef} // Attach the ref to the FileUpload component
                                    name="passport"
                                    multiple = {false}
                                    accept="image/*"
                                    previewWidth={100}
                                    maxFileSize={1000000}
                                    onSelect={onUpload}
                                    customUpload={true}                                
                                    auto={true}
                                    emptyTemplate={<p className="m-0">Drag and drop your Passport(Photo) to here to upload.</p>}
                                    onError={(err) => setErrorMessages((prev) => ({ ...prev, passport: err.message }))}
                                />
                            </div>

                                   
                            </div>
                            <div className="flex pt-4 justify-content-between">
                                <Button label="Back" severity="secondary" className='text-green-500' icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                                <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} disabled={errorMessages.length > 0} />
                            </div>
                            </div>
                        </StepperPanel>
                    

                    

                        <StepperPanel header="Bank Details">
                            <div className="flex flex-column h-auto my-6">
                                <div className="border-2 border-dashed surface-border border-round px-4 py-6 w-full surface-ground  justify-content-center align-items-center font-medium">

                                    <InputField
                                        id="bvn"
                                        label="BVN Number"
                                        type="number"
                                        name="bvn"
                                        required={true}
                                        minLength={1}
                                        maxLength={11}
                                        placeholder="BVN"
                                        value={formData.bvn}
                                        onChange={(e)=>{handleInputChange('bvn',e.target.value)}}
                                        error={errorMessages?.bvn}
                                    />


                                    <InputField
                                        id="bizipay_account"
                                        label="Bizi Pay Account Number"
                                        type="text"
                                        minLength={1}
                                        maxLength={11}
                                        name="bizipay_account"
                                        required={true}
                                        placeholder="Enter Bizi Pay Account Number"
                                        value={formData.bizipay_account}
                                        onChange={(e)=>{handleInputChange('bizipay_account',e.target.value)}}
                                        error={errorMessages?.bizipay_account}
                                    />

                                
                                        <>
                                        <InputField
                                        id="moniepoint_account"
                                        label="Moniepoint Account Number"
                                        type="text"
                                        name="moniepoint_account"
                                        minLength={1}
                                        maxLength={11}
                                        required={true}
                                        placeholder="Enter your Moniepoint Account Number"
                                        value={formData.moniepoint_account}
                                        onChange={(e)=>{handleInputChange('moniepoint_account',e.target.value)}}
                                        error={errorMessages?.moniepoint_account}
                                    />
                                  
                                        </>
                                 



                            </div>
                            </div>
                            <div className="flex pt-4 justify-content-start mb-4">
                                <Button label="Back" severity="secondary" className='text-green-500' icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />

                            </div>


                            <div className='flex flex-wrap'>
                              {
                                accountMatched? (
                                    <button
                                    type="submit"
                                    disabled={processing}
                                    className={`hover:shadow-form grid grid-cols-2 w-full items-center justify-content-center py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-green-300' : 'bg-green-500'}`}
                                      >
                                          {processing && (
                                              <div className="items-center">
                                                  <ProgressSpinner style={{ width: '30px', height: '30px', color: 'red' }} strokeWidth="6" fill="none" animationDuration=".2s" />
                                              </div>
                                          )}
                                          <div className="items-center justify-center text-white">
                                              Submit
                                          </div>
                                  </button>
                                ):(
                                    <span className=' text-white px-2 py-4 border bg-red-500 mx-auto text-center flex flex-row  items-center justify-center'> <FaExclamationCircle width={4} /> Moniepoint Account Name Must Matched with Customer Name</span>
                                )
                              }
                            </div>


                        </StepperPanel>
                    </Stepper>
                </div>
                </form>
            </div>
        </div>

</div>
    );
}
