import React from "react";
import './paliative.png'

 const  Logo = () => {
            return(
                <>
                     <img src="https://ktapi.scan-verify.com/logo/ktlogo.png" className='w-60 h-50'/>
                </>
            )
   };

export default Logo;
